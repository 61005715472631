/*================ Site name ================*/

/**
 * Format site name typography
 */
.site-name {
  @include type--medium;
  @include type--caps;

  @include media-query($large-up) {
    @include type--large;
  }
}

/**
 * Limit line length
 */
.site-name {
  display: block;
  width: 8em;
}

/**
 * Remove underline from link
 */
.site-name {
  @extend .link-no-underline;
}
