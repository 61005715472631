/*================ Bio ================*/

/**
 * Type formatting
 */
.bio {
  @include type--medium;

  @include media-query($large-up) {
    @include type--large;
  }
}

/**
 * Limit line length
 */
.bio {
  max-width: 28.75rem;

  @include media-query($large-up) {
    max-width: 52.5rem;
  }
}

/**
 * Format list
 */
.bio {

  ul {
    list-style: none;
    margin-top: -#{$lh-h0}em;
    padding-left: 0;
  }
}
