/*================ Site footer ================*/

/**
 * Vertical spacing
 */
.site-footer {
  @include m-t-spacer-($spacer-xxl);
  @include p-b-spacer-($spacer-lg);
}

/**
 * Format type
 */
.site-footer {
  @include type--small;

  @include media-query($large-up) {
    @include type--medium;
  }
}
