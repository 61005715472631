// -----------------------------------------------------------------------------
// This file defines the CSS animations used within the site.
// -----------------------------------------------------------------------------

$trans--time: 0.2s;
$trans--function: ease;

@mixin transition--($value) {
  transition: $value $trans--time $trans--function;
}

/**
 * Animations
 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideLeft {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}
