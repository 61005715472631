// -----------------------------------------------------------------------------
// This file defines the fonts used within the type styles.
// -----------------------------------------------------------------------------

/**
 * @license
 * MyFonts Webfont Build ID 3791351, 2019-07-30T09:13:20-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: NHaasGroteskDSPro-55Rg by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-display-55-roman/
 * Licensed pageviews: 1,000,020
 *
 * Webfont: NHaasGroteskTXPro-55Rg by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-text-55-roman/
 * Licensed pageviews: 1,000,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3791351
 * Webfonts copyright: Copyright &#x00A9; 2013 Monotype Imaging Inc. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/39d9f7");
@font-face {font-family: 'NHaasGroteskDSPro-55Rg';src: url('../fonts/39D9F7_0_0.eot');src: url('../fonts/39D9F7_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/39D9F7_0_0.woff2') format('woff2'),url('../fonts/39D9F7_0_0.woff') format('woff'),url('../fonts/39D9F7_0_0.ttf') format('truetype');}
@font-face {font-family: 'NHaasGroteskTXPro-55Rg';src: url('../fonts/39D9F7_1_0.eot');src: url('../fonts/39D9F7_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/39D9F7_1_0.woff2') format('woff2'),url('../fonts/39D9F7_1_0.woff') format('woff'),url('../fonts/39D9F7_1_0.ttf') format('truetype');}


// 1. Non-webfont to emulate FOUT before fonts are loaded
// 2. Use webfont once it’s loaded
// https://www.bramstein.com/writing/web-font-loading-patterns.html

@mixin font--body {
  font-family: sans-serif; /* 1 */
  font-style: normal;
  font-weight: normal;

  @at-root .text-font-loaded & {
    font-family: 'NHaasGroteskTXPro-55Rg', 'Helvetica Neue', 'Helvetica', 'Arial' sans-serif; /* 2 */
  }
}

@mixin font--display {
  font-family: sans-serif; /* 1 */
  font-style: normal;
  font-weight: normal;

  @at-root .display-font-loaded & {
    font-family: 'NHaasGroteskDSPro-55Rg', 'Helvetica Neue', 'Helvetica', 'Arial' sans-serif; /* 2 */
  }
}
