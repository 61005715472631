/*================ Layout ================*/

/**
 * Outer margins
 */
.site-inner-wrapper {
  @include p-t-spacer-($spacer-md);
  @include p-x-spacer-($spacer-md);
}

/**
 * Min height
 */
.site-inner-wrapper {
  min-height: 100vh;
}

.page-content {
  min-height: 40vh;
}

/**
 * Show/hide blocks per template
 */
[data-namespace="home"] {
  .site-header,
  .site-footer {
    display: none;
  }
}
