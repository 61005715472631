/*================ Site navigation menu ================*/

/**
 * Type formatting
 */
.site-nav {
  @include type--small;
  @include type--caps;

  @include media-query($large-up) {
    @include type--medium;
  }
}

/**
 * Positioning
 */
.site-header {
  position: fixed;
  top: $spacer-md;
  right: $spacer-md;
  z-index: 10;
}
