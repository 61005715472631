// -----------------------------------------------------------------------------
// This file sets basic styles used across all Dos sites.
// -----------------------------------------------------------------------------

/**
 * Apply a natural box layout model to all elements,
 * but allowing components to change
 * https://www.paulirish.com/2012/box-sizing-border-box-ftw/
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Ensure body element fills the page
 */
html,
body {
  height: 100%;
}

/**
 * Treat images as block level by default
 */
img {
  display: block;
}

/**
 * Ensure images work responsively
 */
img {
  width: 100%;
}

/**
 * Ensure text looks good
 */
body,
input,
textarea,
button,
select {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

/**
 * Prevent iOS native styling on form elements
 */
textarea,
input[type='text'],
input[type='button'],
input[type='submit'],
input[type='email'],
input[type='password'] {
  -webkit-appearance: none;
  border-radius: 0;
}

/**
 Remove formatting on h tags
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: inherit;
}

/**
 * Remove italic formatting on address element
 */
address {
  font-style: normal
}

/**
 * Remove margin round figure element
 */
figure {
  margin: 0;
  padding: 0;
}

/**
 * Reset padding on lists
 */
ul,
ol {
  padding-left: 1em;
}
