/*================ Homepage ================*/

/**
 * Prevent scrolling
 */
.no-touchevents .barba-container[data-namespace="home"] {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/**
 * Position link wrapper
 */
.no-touchevents .home-link-to-stories {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

/**
 * Position the gallery
 */
.home-gallery {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/**
 * Show/hide portrait/landscape galleries
 */
.home-gallery--landscape {
  display: none;
}

@media (min-aspect-ratio: 1/1) {

  .home-gallery--portrait {
    display: none;
  }

  .home-gallery--landscape {
    display: block;
  }
}

/**
 * Format the gallery slides
 */
.home-gallery__slide-image {
  width: 100vw;
  background-size: cover;
  background-position: top center;
}

.no-touchevents .home-gallery__slide-image {
  height: 100vh;
}

/**
 * Keep site name on top
 */
.barba-container[data-namespace="home"] .site-name {
  position: absolute;
  top: $spacer-md;
  left: $spacer-md;
  z-index: 1;
}

/**
 * Set colour of text
 */
.barba-container[data-namespace="home"] {

  .bg-check.background--light {
    color: $col--home-text-dark;

    .faux-link {
      color: $col--home-text-dark;
      background-image: linear-gradient(transparent 50%, $col--home-text-dark 50%);
    }
  }

  .bg-check.background--dark {
    color: $col--home-text-light;

    .faux-link {
      color: $col--home-text-light;
      background-image: linear-gradient(transparent 50%, $col--home-text-light 50%);
    }
  }
}

/**
 * Remove underline from link
 */
.home-link-to-stories {
  @extend .link-no-underline;
}

/**
 * Format enter link
 */
.site-name--home__cta {
  @include type--caps;
  @include type--small;

  @include media-query($large-up) {
    @include type--medium;
  }
}

/**
 * Homepage fade in text in sequence
 */
.site-name--home__name,
.site-name--home__cta {
  opacity: 0;
}
.background--dark .site-name--home__name,
.background--light .site-name--home__name {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  animation-duration: 0.5s;
}

.background--dark .site-name--home__cta,
.background--light .site-name--home__cta {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
  animation-duration: 0.5s;
}

/**
 * Remove iOS grey box from active link
 */
.home-link-to-stories {
  -webkit-tap-highlight-color: transparent;
}

/**
 * Remove margins
 */
[data-namespace="home"] .site-inner-wrapper {
  padding: 0;
}
