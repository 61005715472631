// -----------------------------------------------------------------------------
// This file defines the type styles used within the design system.
// -----------------------------------------------------------------------------

// Body
@mixin type--body {
  @include font--body;
  @include size--1;
  @include line-height--0;
  letter-spacing: -0.05em;
}

// Large text
@mixin type--large {
  @include font--display;
  @include size--2;
  letter-spacing: -0.05em;
  word-spacing: 0.1em;
}

// Medium text
@mixin type--medium {
  @include size--1;
  letter-spacing: -0.05em;
}

// Small text
@mixin type--small {
  @include size--0;
  letter-spacing: -0.05em;
}

// Site name
@mixin type--caps {
  text-transform: uppercase;
}
