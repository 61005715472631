/*================ Story ================*/

/**
 * Type formatting
 */
.story__text {
	@include type--medium;
	@include type--caps;

	@include media-query($large-up) {
		@include type--large;
	}
}

/**
 * Limit line lengths
 */
.story__text {
	width: 8em;
}

.story__images {
	width: 100%;

	@include media-query($medium-up) {
		width: 75%;
	}
}

/**
 * Remove bottom margins from nested elements
 */
.story__text {

	h1,
	h2,
	p {
		margin-bottom: 0;
	}
}

/**
 * Add bottom margin to wrapper
 */
.story__text {
	margin-bottom: calc(#{$spacer-md} - 0.1875rem);
}

/**
 * Format image floats
 */
.story__images {
	@include clearfix;
}

.story__images a {
	float: left;
}


/**
 * Format image sizes
 */
.story__images .image {
	height: 7.5rem;
	width: auto;

	@include media-query($large-up) {
		height: 12.5rem;
	}
}

/**
 * Add margin around images
 */
.story__images .story__link {
	@include m-r-spacer-($spacer-md);
	@include m-b-spacer-($spacer-md);
}

/**
 * Format back link
 */
.story__back-link {
	@include type--caps;
	@include type--small;

	@include media-query($large-up) {
		@include type--medium;
	}
}

/**
 * Add video play button
 */
.show-play-icon {
	display: block;
	position: relative;

	&::before {
		content: '';
		background-image: url('../img/play.svg');
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		z-index: 1;
		width: 3.125rem;
		height: 3.125rem;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
		filter: drop-shadow(0 0 0.125rem rgba(0,0,0,0.5));
	}
}
