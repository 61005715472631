/*================ Transtions ================*/

/**
 * Define initial load transition
 */
.new-session .page-content,
.new-session .site-header,
.new-session .site-footer {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
}

/**
 * Define between pages transition
 */
.fade-out .page-content,
.fade-out .site-footer {
  animation-name: fadeOut !important;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
}

.fade-in .page-content,
.fade-in .site-footer {
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
}
