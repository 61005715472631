// -----------------------------------------------------------------------------
// This file defines the font sizes used within the design system.
// -----------------------------------------------------------------------------

// Font sizes
$fs-s0: 1rem;   // 16px
$fs-s1: 1.5rem; // 24px
$fs-s2: 3rem;   // 48px

@mixin size--0 {
  font-size: $fs-s0;
}

@mixin size--1 {
  font-size: $fs-s1;
}

@mixin size--2 {
  font-size: $fs-s2;
}
